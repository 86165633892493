var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-sheet',{staticClass:"mx-auto",staticStyle:{"background-color":"transparent"},attrs:{"max-width":"750px"}},[_c('v-slide-group',{staticClass:"pa-4",attrs:{"multiple":true,"show-arrows":_vm.item.creatives.length > 4 ? 'always' : 'mobile'},model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}},_vm._l((_vm.item.creatives),function(creative){return _c('v-slide-item',{key:creative.id,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('div',{staticClass:"d-flex flex-column align-center"},[_c('small',{staticClass:"mb-n2",staticStyle:{"font-size":"0.7em"}},[_vm._v(_vm._s(creative.date))]),_c('v-card',{staticClass:"ma-4",attrs:{"color":"gray","height":_vm.getCreativeSize(creative).height,"width":_vm.getCreativeSize(creative).width},on:{"click":function($event){toggle()
                    _vm.handleCardClick(creative)}}},[_c('v-row',[(creative.media.mimeType.includes('video/'))?[_c('video',{staticClass:"rounded mx-auto mt-3 elevation-3",staticStyle:{"object-fit":"cover"},attrs:{"src":creative.media.url,"height":_vm.getCreativeSize(creative).height,"width":_vm.getCreativeSize(creative).width,"controls":""},on:{"click":function($event){$event.preventDefault();active = !active}}})]:[_c('v-img',{staticClass:"rounded mx-auto mt-3",style:('max-width:'+_vm.getCreativeSize(creative).width+'px; max-height:'+_vm.getCreativeSize(creative).height+'px'),attrs:{"src":creative.media.urlThumb,"height":_vm.getCreativeSize(creative).height,"width":_vm.getCreativeSize(creative).width}},[[_c('v-row',{staticClass:"fill-height ma-0",style:(active ? 'background-color: rgba(0,0,0,0.25)' : 'background-color: transparent'),attrs:{"align":"center","justify":"center"}},[_c('v-scale-transition',[(active)?_c('div',{staticClass:"d-flex flex-column align-center"},[_c('v-icon',{staticClass:"mb-2",attrs:{"large":""}},[_vm._v("mdi-check-circle-outline")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var hover = ref.hover;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"large":"","color":hover ? 'primary' : 'white'},on:{"click":function($event){$event.stopPropagation();return _vm.downloadItem(creative)}}},'v-icon',attrs,false),on),[_vm._v("mdi-download-circle-outline")])]}}],null,true)})]}}],null,true)},[_c('span',[_vm._v("Download Image")])])],1):_vm._e()])],1)]],2)]],2)],1),_c('h4',{staticClass:"text-caption mt-n2"})],1)]}}],null,true)})}),1)],1),_c('v-expand-transition',[(_vm.selectedCreative.length > 0)?_c('v-sheet',{staticClass:"mx-auto",staticStyle:{"background-color":"transparent"},attrs:{"tile":"","elevation":"0","max-width":"85%"}},[_c('v-row',{staticClass:"fill-height",attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"d-flex flex-column align-center mb-4",attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"ma-0 pa-0",staticStyle:{"width":"600px"},attrs:{"items":_vm.item.creatives,"hide-default-footer":""},scopedSlots:_vm._u([{key:"body",fn:function(ref){
                    var items = ref.items;
return [_c('tbody',_vm._l((_vm.headers),function(header){return _c('tr',[_c('td',{staticStyle:{"border-right":"1px solid gray","position":"sticky","left":"0","max-width":"100px","min-width":"100px"},style:(_vm.$vuetify.theme.dark ? 'background-color: #1E1E1E;' : 'background-color: #f5f5f5')},[_vm._v(" "+_vm._s(header.text)+" ")]),_vm._l((_vm.selectedCreative),function(item){return _c('td',[(header.value === 'date')?[_vm._v(" "+_vm._s(item.date)+" ")]:_vm._e(),(header.value === 'pod')?[_vm._v(" "+_vm._s(item.pod)+" ")]:_vm._e(),(header.value === 'location')?[_vm._v(" "+_vm._s(item.location)+" ")]:_vm._e(),(header.value === 'scheduling')?[_vm._v(" "+_vm._s(item.scheduling)+" % ")]:_vm._e()],2)})],2)}),0)]}}],null,false,2189510853)})],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }