

<template>
<div>
    <v-sheet
        class="mx-auto"
        style="background-color: transparent"
        max-width="750px"
    >
    <v-slide-group
        v-model="selectedItems"
        :multiple=true
        class="pa-4"
        :show-arrows="item.creatives.length > 4 ? 'always' : 'mobile'"
    >
    <v-slide-item
        v-for="creative in item.creatives"
        :key="creative.id"
        v-slot="{ active, toggle }"
    >
        <div class="d-flex flex-column align-center">
            <!-- Date above creative -->
            <small class="mb-n2" style="font-size: 0.7em;">{{ creative.date }}</small>
            <!-- Creative -->
            <v-card
                color='gray'
                class="ma-4"
                :height='getCreativeSize(creative).height'
                :width="getCreativeSize(creative).width"
                @click="
                    toggle()
                    handleCardClick(creative)
                "
                >
                <v-row>
                    <template v-if="creative.media.mimeType.includes('video/')" class="d-flex flex-row">
                        <!-- Case video landscape -->
                        <video 
                            :src="creative.media.url"
                            :height='getCreativeSize(creative).height'
                            :width="getCreativeSize(creative).width"
                            controls
                            @click.prevent="active = !active"
                            class="rounded mx-auto mt-3 elevation-3"
                            style="object-fit: cover;"
                        ></video>
                    </template>
                    <template v-else>
                        <!-- Case img landscape -->
                        <!-- Case img portairt -->
                        <v-img
                            :src="creative.media.urlThumb"
                            :height='getCreativeSize(creative).height'
                            :width="getCreativeSize(creative).width"
                            :style="'max-width:'+getCreativeSize(creative).width+'px; max-height:'+getCreativeSize(creative).height+'px'"
                            class="rounded mx-auto mt-3"
                        >
                        <!-- Icon in the middle overlay when pressed-->
                        <template>
                            <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                                :style="active ? 'background-color: rgba(0,0,0,0.25)' : 'background-color: transparent'"
                            >   
                                <v-scale-transition>
                                    <div v-if="active" class="d-flex flex-column align-center">
                                        <!-- Check btn-->
                                        <v-icon large class="mb-2"
                                        >mdi-check-circle-outline</v-icon>
                                        <!-- Download btn -->
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-hover v-slot:default="{ hover }">
                                                    <v-icon large
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        :color="hover ? 'primary' : 'white'"
                                                        @click.stop="downloadItem(creative)"
                                                    >mdi-download-circle-outline</v-icon>
                                                </v-hover>
                                            </template>
                                            <span>Download Image</span>
                                        </v-tooltip>
                                    </div>
                                </v-scale-transition>
                            </v-row>   
                        </template>
                        </v-img>
                    </template>
                </v-row>
            </v-card>
            <!-- Creative name -->
            <h4 class="text-caption mt-n2"></h4>
        </div>
    </v-slide-item>
    </v-slide-group>
    </v-sheet>
    <!-- Creative info -->
    <v-expand-transition>
        <v-sheet
            v-if="selectedCreative.length > 0"
            tile
            elevation="0"
            style="background-color: transparent"
            max-width="85%"
            class="mx-auto"
        >
            <v-row
                class="fill-height"
                align="center"
                justify="center"
            >
            <v-col cols="12" class="d-flex flex-column align-center mb-4">
                <v-data-table 
                    :items="item.creatives"
                    class="ma-0 pa-0"
                    hide-default-footer
                    style="width: 600px;"
                >
                    <!-- Header of left hand size-->
                    <template v-slot:body="{ items }">
                        <tbody>
                        <tr v-for="header in headers">
                            <td 
                                :style="$vuetify.theme.dark ? 'background-color: #1E1E1E;' : 'background-color: #f5f5f5'"
                                style="
                                    border-right: 1px solid gray; 
                                    position: sticky; 
                                    left: 0; 
                                    max-width: 100px; 
                                    min-width: 100px">
                                {{ header.text }}
                            </td>
                            <td v-for="item in selectedCreative">
                                <template v-if="header.value === 'date'">
                                    {{ item.date }}
                                </template>
                                <template v-if="header.value === 'pod'">
                                    {{ item.pod }}
                                </template>
                                <template v-if="header.value === 'location'">
                                    {{ item.location }}
                                </template>
                                <template v-if="header.value === 'scheduling'">
                                    {{ item.scheduling }} %
                                </template>
                            </td>
                        </tr>
                        </tbody>
                    </template>
                </v-data-table>
            </v-col>
                <!-- Display selectedCreative.specs in a grid of 3 items per row with a header and content
                <v-col cols="12" class="d-flex flex-column align-center">
                    <h4>Specs - {{ selectedCreative.media.fileName }}</h4>
                    <v-row class="my-2">
                        <v-col cols="4" class="d-flex flex-column align-center">
                            <h5>SOV</h5>
                            <p>SOV Data for {{ selectedCreative.media.fileName }}</p>
                        </v-col>
                        <v-col cols="4" class="d-flex flex-column align-center">
                            <h5>Notes</h5>
                            <p>{{ selectedCreative.media.fileName }}</p>
                        </v-col>
                        <v-col cols="4" class="d-flex flex-column align-center">
                            <h5>Sched/Inst</h5>
                            <p>{{ selectedCreative.media.fileName }}</p>
                        </v-col>
                    </v-row>
                </v-col>-->
            </v-row>
        </v-sheet>
    </v-expand-transition>
</div>
</template>
<script>
/** Additional packages */
import axios from 'axios'
// Misc
import moment from 'moment'

export default {
    props:{
        item: {
            type: Object,
            required: true
        }
    },
    filters: {
        moment: function (date) {
        return moment(date).format('DD MMMM YYYY')
        },
    },
    data: () => {
        return {
            selectedItems: null,
            selectedCreative: [],
            headers: [
                { text: 'Dates', value: 'date' },
                { text: 'Part of Day', value: 'pod' },
                { text: 'Region', value: 'location' },
                { text: 'Share of Voice', value: 'scheduling' },
            ],
        }
    },
    inject: ['getCreativeSize'],
    beforeMount() {
        // for every creative in item.creatives, add a property 'selected' to false
        if(this.item) {
            this.item.creatives.forEach(creative => {
                // Format the dates with moment and use safeguards
                if(creative.triggers.date){
                    let startDate = creative.triggers.date.startDate
                    startDate = moment().format('DD MMMM YYYY')
                    let endDate = creative.triggers.date.endDate
                    endDate = moment().format('DD MMMM YYYY')
                    var date = startDate + ' - ' + endDate
                }
                // Format times
                if(creative.triggers.time){
                    let startTime = creative.triggers.time.startDate
                    startTime = moment().format('HH:mm:ss')
                    let endTime = creative.triggers.time.endDate
                    endTime = moment().format('HH:mm:ss')
                    var time = startTime + ' - ' + endTime
                }
                creative.date = date ? date : 'no date'
                creative.pod = time ? time : 'no time'
                creative.location = creative.triggers.region ? creative.triggers.region.regions : 'no location'
                creative.scheduling = creative.triggers.sov ? creative.triggers.sov.percentage : 'no sov'
            })
            this.$nextTick()
        }
    },
    methods: {
        handleCardClick(creative) {
            // If creative exists in this.selectedCreative, remove it
            if(this.selectedCreative.includes(creative)) {
                this.selectedCreative = this.selectedCreative.filter(item => item !== creative)
                return
            }
            this.selectedCreative = [...this.selectedCreative, creative]
            this.$nextTick()
        },
        async downloadItem({ media }) {
            let downloadUrl
            let downloadFileName
            let mimeType
            downloadUrl = media.url
            downloadFileName = media.originalFileName
            mimeType = media.mimeType
            let cachebuster = Math.round(new Date().getTime() / 1000);
            const response = await axios.get(downloadUrl +"?rn="+cachebuster, { responseType: "blob" });
            const blob = new Blob([response.data], { type: mimeType });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = downloadFileName;
            link.click();
            URL.revokeObjectURL(link.href);
        },

    }
}
</script>
<style scoped>
::v-deep .v-slide-group__content {
  justify-content: center;
}
</style>