<template>
    <v-container>
        <v-row class="d-flex align-center">
            <v-col cols="auto">
                <v-icon
                    large
                    class="mb-4"
                >mdi-brush</v-icon>
                <span class="text-h2 ml-2">Creative Flighting Builder</span>
                <v-row class="align-center ml-n1 my-1">
                    <v-icon
                    large
                    >mdi-office-building</v-icon>

                    <span v-for="(mo,index) in selectedMO" :key="index" class="text-h3 ml-2">{{ mo ? mo.friendlyName : 'Media Owner' }}  {{ selectedMO.length > 1 ? ' - ' : '' }}</span>
                </v-row>
            </v-col>
            <v-col cols="auto" class="d-flex ml-auto mr-1" style="gap: 0.5em;">
                <v-tooltip top>
                    <template #activator="{ on }">
                        <v-hover v-slot="{ hover }">
                            <v-btn
                            v-on="on"
                            @click="generatePublicLink()"
                            :color="hover ? 'primary' : $vuetify.theme.dark ? '#272727' : '#272727'"
                            fab
                            small
                            style="transition: 0.3s;"
                            ><v-icon>mdi-earth</v-icon></v-btn>
                        </v-hover>
                    </template>
                    <span>Copy Public Link</span>
                </v-tooltip>
                <v-tooltip top>
                    <template #activator="{ on }">
                        <v-hover v-slot="{ hover }">
                            <v-btn
                            v-on="on"
                            @click="showFilters = !showFilters"
                            :color="hover || showFilters ? 'primary' : $vuetify.theme.dark ? '#272727' : '#272727'"
                            fab
                            small
                            style="transition: 0.3s;"
                            >
                            <v-icon>mdi-filter</v-icon>
                            </v-btn>
                        </v-hover>
                    </template>
                    <span>Toggle Filters</span>
                </v-tooltip>
                <v-tooltip top>
                    <template #activator="{ on }">
                        <v-hover v-slot="{ hover }">
                            <v-btn
                            v-on="on"
                            @click="generateExcel()"
                            :color="hover ? 'primary' : $vuetify.theme.dark ? '#272727' : '#272727'"
                            fab
                            small
                            style="transition: 0.3s;"
                            ><v-icon>mdi-database-export</v-icon></v-btn>
                        </v-hover>
                    </template>
                    <span>Export Excel</span>
                </v-tooltip>
            </v-col>
        </v-row>
        <v-row>
            <v-data-table
                :headers="headers"
                :items="formats"
                class="ma-0 pa-0"
                style="width:100%"
            >
                <!-- Fallback -->
                <template v-slot:item.fallback="{ item }">
                    <video
                        v-if="item.fallback.media.mimeType.includes('video/')"
                        :src="item.fallback.media.url"
                        :height='getCreativeSize(item.fallback).height'
                        :width="getCreativeSize(item.fallback).width"
                        class="rounded mx-auto my-4 mt-8 elevation-3"
                        style="object-fit: cover;"
                        controls
                    ></video>
                    <v-img
                        v-else
                        :src="item.fallback.media.urlThumb"
                        :height='getCreativeSize(item.fallback).height'
                        :width="getCreativeSize(item.fallback).width"
                        :style="'max-width:'+getCreativeSize(item.fallback).width+'px; max-height:'+getCreativeSize(item.fallback).height+'px'"
                        class="rounded mx-auto my-4 mt-8 elevation-3"
                    />
                </template>
                <!-- Creatives Carousel-->
                <template v-slot:item.creatives="{ item }">
                    <DataItem :item="item" />
                </template>
            </v-data-table>
        </v-row>
    </v-container>
</template>
<script>
/** Controllers */  
import CampaignController from '@/services/controllers/Campaign'
// Components
import DataItem from './DataItem.vue'
// Triggers
const triggersList = require(`@/assets/${process.env.VUE_APP_TRIGGERS_VERSION}`)

export default {
    data() {
        return {
            // Selected Data
            selectedMO: [],
            selectedItem: null,
            selectedCreative: null,
            selectedScreens: [],
            // Filters
            showFilters: false,
            // Data Loaded
            overlay: true,
            // Table Headers
            headers: [
                { text: "#", value: "id", sortable: true, align: "start", width: '5%' },
                {
                    text: "Format",
                    align: "center",
                    sortable: false,
                    value: "name",
                    width: '10%'
                },
                { text: "Fallback", value: "fallback", sortable: false, align: "center", width: '15%' },
                { text: "Creatives", value: "creatives", sortable: false, align: "center", width: '70%' },
            ],
            // Table Data
            formats: [],
            triggerJson: triggersList,
            allFields: [
                {id: 1, name: 'Dates', jsonValue: 'date'},
                {id: 2, name: 'Part of Day', jsonValue: 'time'},
                {id: 3, name: 'Location', jsonValue: 'region'},
                {id: 4, name: 'Share of Voice', jsonValue: 'sov'}
            ]
        };
    },
    computed: {
        // Get creative size based on orientation
        getCreativeSize() {
            return (creative) => {
                if(creative.media.mimeType.includes('video/')) return { height: 200, width: 120 }
                if(creative.media.orientation === 'Portrait') {
                    return {
                        height: 200,
                        width: 120
                    }
                } else {
                    return {
                        height: 100,
                        width: 200
                    }
                }
            }
        }
    },
    async created(){
        if(this.$route.query.cid) this.fetchCampaignData()
    },
    methods: {
        async fetchCampaignData() {
            try {
                const res = await CampaignController.getCampaign(this.$route.query.cid);

                // Find the burst by id in this.$route.query.bid
                let selectedBursts = res.data.campaignBursts.filter(burst => this.$route.query.bid.includes(burst.id));
                
                // Iterate through selectedBursts and make a call to getCampaignBurstScreenContents for selectedScreens, only if the screen id is in this.$route.query.sid
                for (let i = 0; i < selectedBursts.length; i++) {
                let burst = selectedBursts[i];
                let selectedScreens = burst.campaignBurstScreens.filter(screen => this.$route.query.sid.includes(screen.id));
                let searchScreenLevelTriggers = (what) => selectedScreens.find(element => element.id === what)
                let fields = this.$route.query.fields ? this.$route.query.fields.split(',') : []
                // filter allFields with the fields in this.$route.query.fields
                fields = this.allFields.filter(field => fields.includes(field.id))
                if (selectedScreens.length > 0) {
                    for (let j = 0; j < selectedScreens.length; j++) {
                        let screen = selectedScreens[j];
                        // Get triggers added to screen level.
                        let screenLevelTriggers = searchScreenLevelTriggers(parseInt(selectedScreens[j].id)).triggerList
                        let filterScreenLevelTriggers = []
                        // FIlter the screenLevelTriggers to include only the triggers that match the field.jsonValue
                        screenLevelTriggers.forEach(trigger => {
                            this.allFields.forEach(field => {
                                if(trigger === field.jsonValue) {
                                    filterScreenLevelTriggers.push(trigger)
                                }
                            })})
                        const res = await CampaignController.getCampaignBurstScreenContents(screen.id)
                        let creatives = res.data
                        const modifiedCreatives = [];

                        for (let i = 0; i < creatives.length; i++) {
                            const creative = creatives[i]
                            // Deep copy triggers using JSON methods
                            creative.triggers = JSON.parse(creative.triggers)
                            // Filter the triggers to include only the triggers that match the field.jsonValue
                            let creativeTriggers = {}
                            for (const [key, value] of Object.entries(creative.triggers)) {
                                filterScreenLevelTriggers.forEach(trigger => {
                                    if(key === trigger) creativeTriggers[key] = value
                                })
                            }
                            // Determine cbsStartDate and cbsEndDate
                            creative.cbsStartDate = burst.startDate
                            creative.cbsEndDate = burst.endDate
                            // Assign the modified triggers to the creative
                            creative.triggers = creativeTriggers
                            if(!creative.triggers.date) {
                                creative.triggers.date = {
                                    startDate: burst.startDate,
                                    endDate: burst.endDate
                                }
                            }
                            // Push the modified creative to the array
                            modifiedCreatives.push(creative)
                        }
                        let screenContents = {
                            id: screen.id,
                            name: screen.screen.name + ' - ' + selectedBursts[i].name,
                            creatives: modifiedCreatives,
                            fallback: res.data[0] ? res.data[0] : null,
                            fields: fields,
                            triggers: filterScreenLevelTriggers
                        }
                        // Push the screen contents to the formats array
                        if(screenContents.fallback) {this.formats.push(screenContents)}
                    }
                }

                    // Find the mo's in selectedScreens where selectedScreen.screen.mediaOwner.id is included in this.$route.query.mo
                let selectedMOs = selectedScreens.filter(screen => this.$route.query.mo.includes(screen.screen.mediaOwner.id));
                // Push the selectedMOs to the selectedMO array
                selectedMOs.forEach(selectedScreen => {
                    // CHeck if the selectedMO array already includes the selectedScreen.screen.mediaOwner
                    let selectedMO = this.selectedMO.find(mo => mo.id === selectedScreen.screen.mediaOwner.id);
                    if(!selectedMO)
                        this.selectedMO.push(selectedScreen.screen.mediaOwner);
                });
                }
            } catch (error) {
                // Handle errors if any
                console.error(error);
            }
        },
        // Function to copy the public link to the clipboard
        generatePublicLink(){
            let publicLink = window.location.origin + '/#/p-cfs?cid=' + this.$route.query.cid + '&bid=' + this.$route.query.bid + '&sid=' + this.$route.query.sid + '&mo=' + this.$route.query.mo
            navigator.clipboard.writeText(publicLink)
            this.$root.$emit("snackbarSuccess", "Public link copied to clipboard.");
        },
        // Assign the selected creative to the selectedCreative variable
        assignCreative(item) {
            this.selectedCreative = item;
            this.$nextTick();
        }
    },
    provide: function () {
        return {
            getCreativeSize: this.getCreativeSize
        }
    },
    components: { DataItem }
}
</script>
<style scoped>
::v-deep .v-data-table-header th:not(:first-child) span {
  display: flex;
  justify-content: center;
}
</style>